/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  {
    id: 'dates',
    label: 'Dates',
    type: 'BookingDateRangeFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates'],
    config: {},
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'category',
    label: 'Category',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_category'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'garden_rent', label: 'Jardins à louer' },
        { key: 'garden_share', label: 'Jardins à partager' },
        { key: 'tinyhouse', label: 'Jardins à la nuitée' },
        // { key: 'classifields', label: 'Petites Annonces' },
      ],
    },
  },
  {
    id: 'amenities_tinyhouse',
    label: 'Tinyhouse',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_amenities_tinyhouse'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'salon',
          label: 'Salon',
        },
        {
          key: 'sofa_bed',
          label: 'Salon',
        },
        {
          key: 'kitchen',
          label: 'Salon',
        },
        {
          key: 'power',
          label: 'Power',
        },
        {
          key: 'wifi',
          label: 'Wifi',
        },
        {
          key: 'tv',
          label: 'Tv',
        },
        {
          key: 'water',
          label: 'Water',
        },
        {
          key: 'bed_for_1',
          label: 'Bed for 1',
        },
        {
          key: 'bed_for_2',
          label: 'Bed for 2',
        },
        {
          key: 'bed_for_4',
          label: 'Bed 4',
        },
        {
          key: 'bathroom',
          label: 'Bathroom',
        },
        {
          key: 'toilette',
          label: 'Toilette',
        }
      ]
    }
  },
  {
    id: 'amenities_garden_share',
    label: 'Garden Share',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_amenities_garden_share'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'garden_tool',
          label: "Outils de jardinage"
        },
        {
          key: 'sowing_planting',
          label: "Semis & plantations"
        },
        {
          key: 'power',
          label: "Electricité"
        },
      ]
    }
  },
  {
    id: 'amenities_garden_rent',
    label: 'Garden Rent',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_amenities_garden_rent'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'tables',
          label: "Tables & chaises de jardin"
        },
        {
          key: 'chairs',
          label: "Chaises longues"
        },
        {
          key: 'hamac',
          label: "Hamac"
        },
        {
          key: 'terrace',
          label: "Terrasse"
        },
        {
          key: 'parasol',
          label: "Parasol"
        },
        {
          key: 'barnum',
          label: "Barnum"
        },
        {
          key: 'bbq',
          label: "Barbecue"
        },
        {
          key: 'braseiro',
          label: "Braseiro"
        },
        {
          key: 'outdoor_pool',
          label: "Piscine"
        },
        {
          key: 'indoor_pool',
          label: "Piscine d'intérieur"
        },
        {
          key: 'spa',
          label: "Spa"
        },
        {
          key: 'sauna',
          label: "Sauna"
        },
        {
          key: 'outdoor_shower',
          label: "Douche extérieure"
        },
        {
          key: 'indoor_shower',
          label: "Douche intérieure"
        },
        {
          key: 'outdoor_wc',
          label: "WC extérieurs"
        },
        {
          key: 'indoor_wc',
          label: "WC intérieurs"
        },
        {
          key: 'free_space',
          label: "Espace Yoga, Taï-chi-chuan, qi gong"
        },
      ]
    }
  },
  {
    id: 'games',
    label: 'Game',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_games'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'ping_pong',
          label: 'Pingpong',
        },
        {
          key: 'baby_foot',
          label: 'Baby foot',
        },
        {
          key: 'sandbox',
          label: 'Sandbox',
        },
        {
          key: 'badminton',
          label: 'Badminton',
        },
        {
          key: 'tennis_court',
          label: 'Tennis court',
        },
        {
          key: 'swing',
          label: 'Swing',
        },
        {
          key: 'trampoline',
          label: 'Trampoline',
        },
        {
          key: 'petanque',
          label: 'Petanque',
        },
        {
          key: 'inflatable_pool',
          label: 'Inflatable pool',
        }
      ]
    }
  },
  {
    id: 'events',
    label: 'Events',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_events'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'bbq_party',
          label: 'Barbecue Party',
        },
        {
          key: 'birthday',
          label: 'Anniversaire',
        },
        {
          key: 'bapteme',
          label: 'Baptême',
        },
        {
          key: 'wedding',
          label: 'Mariage',
        },
        {
          key: 'event_comp',
          label: "Event d'entreprise",
        },
        {
          key: 'shooting',
          label: 'Shooting Photos',
        },
        {
          key: 'friend_gathering',
          label: 'Barbecue Party',
        },
        {
          key: 'baby_shower',
          label: 'Baby-shower',
        },
        {
          key: 'sport_yoga',
          label: 'Sport-Yoga',
        },
      ]
    }
  },
  {
    id: 'parking',
    label: 'Parking',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_amenities_parking'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'free',
          label: 'Free',
        },
        {
          key: 'private',
          label: 'Private',
        },
        {
          key: 'outside',
          label: 'Outside',
        }
      ]
    }
  },
  {
    id: 'ambiance_tinyhouse',
    label: 'Ambiance Tinyhouse',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_ambiances'],
    config: {
      options: [
        { key: 'cocooning', label: "Cocooning" },
        { key: 'nature', label: 'Nature' },
        { key: 'sea_side', label: 'Bord de mer' },
        { key: 'romantic', label: 'Romantique' }
      ],
    },
  },
  {
    id: 'ambiance_garden',
    label: 'Ambiance Garden',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_ambiances'],
    config: {
      options: [
        { key: 'luxury_garden', label: "Jardin d'exception" },
        { key: 'pool_party', label: 'Pool party' },
        { key: 'well_being', label: 'Bien-être / Zen' },
        { key: 'roof_top', label: 'Rooftop' },
        { key: 'nature_forest', label: 'Nature et forêt' },
        { key: 'fishing', label: 'Lac et pêche' },
        { key: 'bucolic', label: 'Bucolique' }


      ],
    },
  },
  {
    id: 'garden_type',
    label: 'Garden Type',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_garden_type'],
    config: {
      options: [
        { key: 'cultivate', label: "Potager à cultiver" },
        { key: 'maintain', label: 'Jardin à entretenir' },
      ],
    },
  },
  {
    id: 'classifields_type',
    label: 'Classifields Type',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_classifields_type'],
    config: {
      options: [
        { key: 'garden_tool', label: "Outil de jardin" },
        { key: 'sowing_planting', label: 'Semi & plantation' },
      ],
    },
  },
  {
    id: 'price_extras',
    label: 'Price Extra',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_price_extras'],
    config: {
      options: [
        { key: 'bbq_fee', label: "Barbecue - 10 €" },
        { key: 'barnum_fee', label: 'Tonnelle / Barnum - 15 €' },
        { key: 'kitchen_fee', label: "Cuisine d'extérieur / Intérieur - 5 €" },
        { key: 'sound_fee', label: 'Matériel de sono - 10 €' },
        { key: 'spa_fee', label: 'Jacuzzi / Spa - 10 €' }
      ],
    },
  },
  {
    id: 'price_extras_tinyhouse',
    label: 'Price Extra',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_price_extras'],
    config: {
      options: [
        { key: 'bbq_fee', label: "Barbecue - 10 €" },
        { key: 'spa_fee', label: 'Jacuzzi / Spa - 10 €' }
      ],
    },
  },
  {
    id: 'price_terms',
    label: 'Price Terms',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_price_terms'],
    config: {
      options: [
        { key: 'flexible', label: "Flexibles : Annulation jusqu'à 24 heures avant l''heure prévue d'arrivée" },
        { key: 'moderate', label: "Modérées : Annulation 5 jours avant l'heure prévue d'arrivée" },
        { key: 'fixe', label: "Fixes : Annulation 10 jours avant l'heure prévue d'arrivée" }
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};
