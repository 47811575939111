import React from 'react'
import {Grid} from '@material-ui/core'

import css from './Category.module.css'

import {
    NamedLink
} from '../../components'

// Images
import rent from './images/rent.jpg';
import share from './images/share.jpg';
// import post from './images/post.jpg';
import tinyhouse from './images/jo2024-2.jpg';

// &address=France&bounds=51.148409399929%2C9.66015649291518%2C41.3108229%2C-5.24227249992918&mapSearch=true

const Category = props => {

    return <Grid container spacing={2} justify="center">
            <Grid item xs={12} sm={6} md={4} className={css.grid}>
                <div className={css.imageWrapper}>
                <NamedLink
                    name="SearchPage"
                    to={{ search: "?pub_category=garden_rent" }}>
                <img src={rent} className={css.locationImage} alt="rent"/>
                </NamedLink>
                </div>
                <h2>Jardin à louer</h2>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={css.grid}>
                <div className={css.imageWrapper}>
                <NamedLink
                    name="SearchPage"
                    to={{ search: "?pub_category=garden_share" }}>
                    <img src={share} className={css.locationImage} alt="share"/>
                    </NamedLink>
                </div>
                <h2>Jardin à partager</h2>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={css.grid}>
                <div className={css.imageWrapper}>
                    <NamedLink
                        name="SearchPage"
                        to={{ search: "?pub_category=tinyhouse" }}>
                    <img src={tinyhouse} className={css.locationImage} alt="tinyhouse"/>
                    </NamedLink>
                </div>
                <h2>Jardins à la nuitée</h2>
            </Grid>
        </Grid>
}

export default Category